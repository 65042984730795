<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container class="pr-4 pt-0">
            <v-row>
              <v-col
                cols="11"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="1"
                md="2"
                align="right"
                align-self="center"
                class="py-0 pb-2"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="in-out">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                dense
                @submit.prevent="applyFilters()"
              >
                <v-card>
                  <v-form
                    v-model="searchValid"
                    ref="grupo-familiar-filter"
                    id="grupo-familiar-filter"
                    @submit.prevent="filterAfiliados()"
                  >
                    <v-row>
                      <v-col cols="6" md="5" sm="10" class="pt-0 mt-0">
                        <v-card-title class="primary--text pl-3 py-0"
                          >Búsqueda de afiliado</v-card-title
                        >
                      </v-col>
                    </v-row>
                    <v-row class="pl-3">
                      <!-- campo N° documento -->
                      <v-col cols="3" class="py-0">
                        <v-text-field
                          v-model="afiliadoSearchDocumento"
                          label="N° de documento"
                          dense
                          clearable
                          v-mask="'########'"
                          outlined
                          @change="
                            changeAfiliadoSearch(afiliadoSearchDocumento)
                          "
                        >
                        </v-text-field>
                      </v-col>
                      <!-- campo apellido -->
                      <v-col cols="3" class="py-0">
                        <v-text-field
                          v-model="afiliadoSearchApellido"
                          label="Apellido/s"
                          dense
                          :rules="[rules.maxLength(afiliadoSearchApellido, 50)]"
                          clearable
                          outlined
                          @change="changeAfiliadoSearch(afiliadoSearchApellido)"
                        >
                        </v-text-field>
                      </v-col>
                      <!-- campo nombre -->
                      <v-col cols="3" class="py-0">
                        <v-text-field
                          v-model="afiliadoSearchNombre"
                          label="Nombre/s"
                          dense
                          :rules="[rules.maxLength(afiliadoSearchNombre, 50)]"
                          clearable
                          outlined
                          @change="changeAfiliadoSearch(afiliadoSearchNombre)"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3" class="py-1 text-left">
                        <v-btn
                          color="primary"
                          elevation="2"
                          small
                          type="submit"
                          form="grupo-familiar-filter"
                        >
                          Buscar
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        v-if="isSearchGrupo"
                        cols="12"
                        md="12"
                        sm="12"
                        class="py-0 text-center"
                      >
                        <v-card class="pt-0">
                          <v-data-table
                            class="pt-0"
                            :headers="headersSearchAfiliado"
                            :items="gruposFamiliaresResult"
                            :loading="loadingSearchAfiliado"
                            dense
                            fixed-header
                            show-select
                            single-select
                            v-model="grupoSelected"
                            @input="selectedAfiliado()"
                          >
                            <template v-slot:[`header.data-table-select`]>
                              <span>Seleccionar</span>
                            </template>
                          </v-data-table>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card>
                <v-row class="pt-5">
                  <!-- campo concepto -->
                  <v-col cols="4" class="pt-0 pr-0">
                    <v-autocomplete
                      v-model="conceptoSelected"
                      :items="itemsConcepto"
                      label="Concepto"
                      hide-details="auto"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- campo periodo -->
                  <v-col cols="2" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      hide-details="auto"
                      clearable
                      v-model="periodo"
                      label="Período"
                      hint="Formato AAAAMM"
                      type="text"
                      v-mask="'######'"
                      :rules="rules.periodoYyyyMm"
                    ></v-text-field>
                  </v-col>
                  
                  <v-col cols="6" class="pb-0" align="right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      class="to-right"
                      color="primary"
                      elevation="2"
                      small
                      :disabled="!isFormValid"
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemsImportesFijosGrupoFliar"
            class="elevation-1"
            :search="search"
            :expanded.sync="expanded"
            show-expand
            :loading="isLoading"
            item-key="adicionalId"
          >
            <template v-slot:[`item.benGrId`]="{ item }">
              <span :style="item.registroDuplicado ? 'color:red' : ''">
                {{ item.benGrId }}</span
              >
            </template>
            <template v-slot:[`item.afiliadoTit`]="{ item }">
              <span :style="item.registroDuplicado ? 'color:red' : ''">
                {{ item.afiliadoTit }}</span
              >
            </template>
            <template v-slot:[`item.concepto.value`]="{ item }">
              <span :style="item.registroDuplicado ? 'color:red' : ''">
                {{ item.concepto.value }}</span
              >
            </template>
            <template v-slot:[`item.periodoDesde`]="{ item }">
              <span :style="item.registroDuplicado ? 'color:red' : ''">
                {{ item.periodoDesde }}</span
              >
            </template>
            <template v-slot:[`item.periodoHasta`]="{ item }">
              <span :style="item.registroDuplicado ? 'color:red' : ''">
                {{ item.periodoHasta }}</span
              >
            </template>
            <template v-slot:[`item.importeToShow`]="{ item }">
              <span :style="item.registroDuplicado ? 'color:red' : ''">
                {{ item.importeToShow }}</span
              >
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="7" :md="showHelp ? 4 : 7">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" :md="showHelp ? 1 : 3">
                    <v-btn
                      color="primary"
                      class="no-upper-case"
                      v-if="canGoProcesoActualizacion"
                      @click="goToProcesosActualizacionImporteFijo()"
                    >
                      Procesos de actualización
                    </v-btn>
                  </v-col>
                  <v-col cols="2" :md="showHelp ? 7 : 2" v-if="canCreate">
                    <v-btn
                      color="primary"
                      @click="openModalEditImportesFijos()"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    class=""
                    @click="openModalEditImportesFijos(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar importe</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteImportesFijos(item.adicionalId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar importe</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{
                      isExpanded ? "mdi-account-check" : "mdi-account"
                    }}</v-icon
                  >
                </template>
                <span>Usuarios</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <strong>Fecha alta:</strong>
                {{ item.fechaAlta }}
                <strong style="padding-left:50px;">Usuario alta:</strong>
                {{ item.usuAlta }}
                <strong style="padding-left:50px;">Fecha modificación:</strong>
                {{ item.fechaModi }}
                <strong style="padding-left:50px;"
                  >Usuario modificación:</strong
                >
                {{ item.usuModi }}
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-row>
          <v-col cols="10">
            <span
              v-if="itemsImportesFijosGrupoFliar.find(x => x.registroDuplicado)"
              style="color:red"
              >*Existen grupos familiares con dos o más titulares.</span
            >
          </v-col>
          <v-col cols="2" md="2">
            <v-tooltip top max-width="35%">
              <template v-slot:activator="{ attrs }">
                <v-btn
                  color="primary"
                  class="to-right"
                  v-bind="attrs"
                  outlined
                  dense
                  :disabled="itemsImportesFijosGrupoFliar.length == 0"
                  @click="exportExcelConsultaImportesFijos"
                >
                  Exportar detalle
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <DeleteDialog
      :isLoading="loadDeleteBtn"
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      :maxWidth="'40%'"
      @onDeleteItem="confirmDelete()"
    />
    <v-dialog
      v-if="openEditImportesFijos"
      v-model="openEditImportesFijos"
      @keydown.esc="closeAndReload"
      max-width="62%"
      persistent
    >
      <NuevoImporteFijoPorGrupoFliar
        :itemImporteFijo="itemToEdit"
        @closeAndReload="closeAndReload"
      ></NuevoImporteFijoPorGrupoFliar>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mask } from "vue-the-mask";
import DeleteDialog from "@/components/shared/DeleteDialog";
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";
import NuevoImporteFijoPorGrupoFliar from "@/components/modules/cuotas/devengamientos/NuevoImporteFijoPorGrupoFliar.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "ImportesFijosPorGrupoFliar",
  components: {
    Ayuda,
    GoBackBtn,
    PageHeader,
    DeleteDialog,
    FiltersSelected,
    NuevoImporteFijoPorGrupoFliar
  },
  directives: { mask },
  data: vm => ({
    rules: rules,
    search: "",
    showFilters: true,
    showHelp: false,
    optionCode: enums.webSiteOptions.IMPORTES_FIJOS_POR_GRUPO_FLIAR,
    showExpand: false,
    title: enums.titles.IMPORTES_FIJO_GRUPO_FLIAR,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    deleteIcon: enums.icons.DELETE,
    editIcon: enums.icons.EDIT,
    searchIcon: enums.icons.SEARCH,
    filtersApplied: [],
    isFormValid: false,
    isLoading: false,
    canCreate: false,
    canDelete: false,
    canEdit: false,
    canGoProcesoActualizacion: false,
    routeToGo: "ImportesADevengar",
    titleDelete: "¿Desea eliminar el importe fijo?",
    loadDeleteBtn: false,
    showDeleteModal: false,
    expanded: [],
    //busqueda afiliado
    searchValid: null,
    isSearchGrupo: false,
    afiliadoSearchApellido: null,
    afiliadoSearchDocumento: null,
    afiliadoSearchNombre: null,
    loadingSearchAfiliado: false,
    gruposFamiliaresResult: [],
    grupoSelected: [],
    headersSearchAfiliado: [
      {
        text: "Afiliado",
        align: "start",
        sortable: true,
        value: "value"
      },
      {
        text: "N° de documento",
        sortable: false,
        value: "doc"
      },
      {
        text: "N° de grupo familiar",
        sortable: false,
        value: "nroGrupo"
      }
    ],
    //filters
    nroGrupo: null,
    periodo: null,
    conceptoSelected: null,
    itemsConcepto: [],
    itemsImportesFijosGrupoFliar: [],
    headers: [
      {
        text: "N° de grupo familiar",
        sortable: false,
        value: "benGrId",
        align: "left"
      },
      {
        text: "Afiliado titular",
        sortable: false,
        value: "afiliadoTit"
      },
      {
        text: "Concepto",
        sortable: false,
        value: "concepto.value"
      },
      {
        text: "Período desde",
        sortable: false,
        value: "periodoDesde"
      },
      {
        text: "Período hasta",
        sortable: false,
        value: "periodoHasta"
      },
      {
        text: "Importe",
        sortable: false,
        value: "importeToShow",
        align: "end"
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "end",
        width: "5%"
      },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
        align: "end"
      }
    ],
    allowedActions: null,
    openEditImportesFijos: false,
    itemToEdit: null,
  }),
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode",
      this.optionCode
    );
    this.periodo = this.periodoActual();
    this.setSelects();
    this.setPermisos();
    this.applyFilters();
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
  },
  methods: {
    ...mapActions({
      getConceptosAdicionales: "devengamientos/getConceptosAdicionales",
      getImportesFijosADevengarByParams:
        "devengamientos/getImportesFijosADevengarByParams",
      deleteImportesFijosADevengar:
        "devengamientos/deleteImportesFijosADevengar",
      fetchAfiliadosByParams: "agenteCuenta/fetchAfiliadosByParams",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const conceptosAdicionales = await this.getConceptosAdicionales();
      this.itemsConcepto = conceptosAdicionales;

    },
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .ELIMINAR_IMPORTE_FIJO_A_DEVENGAR_POR_GRUPO_FLIAR:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_IMPORTE_FIJO_A_DEVENGAR_POR_GRUPO_FLIAR:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .NUEVO_IMPORTE_FIJO_A_DEVENGAR_POR_GRUPO_FLIAR:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions
            .PROCESOS_ACTUALIZACION_IMPORTE_FIJO:
            this.canGoProcesoActualizacion = true;
            break;
          default:
            break;
        }
      });
    },
    async applyFilters() {
      //se limpia el array para que no de error
      this.itemsImportesFijosGrupoFliar = [];
      this.isLoading = true;
      this.showFilters = false;
      this.customizeFiltersApplied();
      const filters = {
        benGrId: this.grupoSelected.length != 0 ? this.nroGrupo : null,
        periodo: this.periodo,
        conceptoId: this.conceptoSelected?.id
      };
      try {
        const response = await this.getImportesFijosADevengarByParams(filters);
        this.itemsImportesFijosGrupoFliar = response;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = [];
      if (this.nroGrupo && this.grupoSelected?.length > 0) {
        this.filtersApplied.splice(1, 1, {
          key: "nroGrupo",
          label: "N° de grupo familiar",
          model: this.nroGrupo
        });
      }
      if (this.periodo) {
        this.filtersApplied.splice(2, 1, {
          key: "periodo",
          label: "Período",
          model: this.periodo
        });
      }
      if (this.conceptoSelected) {
        this.filtersApplied.splice(3, 1, {
          key: "conceptoSelected",
          label: "Concepto",
          model: this.conceptoSelected.value
        });
      }
      if (
        this.conceptoSelected == null &&
        this.periodo == null &&
        this.grupoSelected?.length === 0
      ) {
        this.filtersApplied.splice(3, 1, {
          key: "todos",
          label: "Concepto, Período y Grupo Familiar",
          model: "Todos"
        });
      }
      if (this.grupoSelected?.length > 0) {
        this.filtersApplied.splice(3, 1, {
          key: "afiliadoSearchNombre",
          label: "Afiliado",
          model: this.grupoSelected[0].value
        });
      }
    },
    async filterAfiliados() {
      if (
        (this.afiliadoSearchNombre != null &&
          this.afiliadoSearchNombre != "") ||
        (this.afiliadoSearchDocumento != null &&
          this.afiliadoSearchDocumento != "") ||
        (this.afiliadoSearchApellido != null &&
          this.afiliadoSearchApellido != "")
      ) {
        this.loadingSearchAfiliado = true;
        const data = {
          apellido: this.afiliadoSearchApellido,
          dni: parseInt(this.afiliadoSearchDocumento),
          nombre: this.afiliadoSearchNombre
        };
        try {
          this.isSearchGrupo = true;
          const response = await this.fetchAfiliadosByParams(data);
          this.gruposFamiliaresResult = response;
          this.loadingSearchAfiliado = false;
        } catch {
          this.loading = false;
        }
      } else {
        this.setAlert({
          type: "warning",
          message: "Ingrese al menos un parámetro de búsqueda"
        });
      }
    },
    
    selectedAfiliado() {
      if (this.grupoSelected.length > 0) {
        this.nroGrupo = this.grupoSelected[0].nroGrupo;
        this.afiliadoSearchApellido = this.grupoSelected[0].afiliadoApe;
        this.afiliadoSearchNombre = this.grupoSelected[0].afiliadoNom;
        this.afiliadoSearchDocumento = this.grupoSelected[0].doc;
        this.isSearchGrupo = false;
      }
    },
    goToProcesosActualizacionImporteFijo() {
      this.$router.push({
        name: "ProcesoActualizacionImportesFijos"
      });
    },
    openModalEditImportesFijos(item) {
      this.itemToEdit = item;
      this.openEditImportesFijos = true;
    },
    deleteImportesFijos(id) {
      this.idToDelete = id;
      this.showDeleteModal = true;
    },
    async confirmDelete() {
      this.loadDeleteBtn = true;
      try {
        const response = await this.deleteImportesFijosADevengar(
          this.idToDelete
        );
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.applyFilters();
          this.loadDeleteBtn = false;
        }
      } catch (error) {
        this.showDeleteModal = false;
        this.loadDeleteBtn = false;
      }
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    closeAndReload() {
      this.openEditImportesFijos = false;
      this.applyFilters();
    },
    resetForm() {
      this.nroGrupo = null;
      this.grupoSelected = [];
      this.$refs["filters-form"].reset();
      this.$refs["grupo-familiar-filter"].reset();
    },
    exportExcelConsultaImportesFijos() {
      let result = [];
      this.itemsImportesFijosGrupoFliar?.forEach(x =>
        result.push({
          ["Nº Grupo Familiar"]: x.benGrId,
          ["Afiliado titular"]: x.afiliadoTit,
          ["Concepto"]: x.concepto.value,
          ["Período desde"]: x.periodoDesde,
          ["Período hasta"]: x.periodoHasta,
          ["Importe"]: x.importeToShow
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado lista"
      };
      helpersExcel.excelExport(
        formato,
        "Consulta importes fijos por grupo familiar"
      );
    },
    changeAfiliadoSearch(campoModified) {
      if (campoModified === null) {
        this.isSearchGrupo = false;
        this.gruposFamiliaresResult = [];
        this.grupoSelected = [];
        this.nroGrupo = null;
      }
    },
    periodoActual() {
      let now = new Date();
      let month = ("0" + (now.getMonth() + 1)).slice(-2);
      let periodoNow = now.getFullYear() + month;

      return periodoNow;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style scoped>
.no-upper-case {
  text-transform: none;
}
.v-data-footer {
  display: flex;
  justify-content: flex-start;
  color: red;
  text-align: center;
  padding: 10px;
}
</style>
